import classNames from "classnames"
import React from "react"

import { Color } from "../../../../constants/V2/color"
import BulletItem from "../../../elements/V2/BulletItem"
import TextButton from "../../../elements/V2/Buttons/TextButton"
import IconInfoBlock from "../../../elements/V2/IconInfoBlock"
import ArrowIcon from "../../../elements/V2/Icons/ArrowIcon"
import ListItem from "../../../elements/V2/ListItem"
import Typography from "../../../elements/V2/Typography"

import { useScrollAnimateSelf } from "@hooks/V2/useScrollAnimate"
import { getAccentColor, getBackgroundColorClass } from "@utils/V2/color"

type MasterBulletPointsProps = {
  itemType: "bullet_items" | "icon_info" | "list_items"
  backgroundColor?: Color
  superscriptText?: string
  descriptionText?: string
  headerSize?: "small" | "large"
  textAlignment?: "left" | "center"
  title: string
  body?: string
  bulletItems?: React.ComponentProps<typeof BulletItem>[]
  listItems?: React.ComponentProps<typeof ListItem>[]
  iconInfoBlocks?: Omit<
    React.ComponentProps<typeof IconInfoBlock>,
    "size" | "textColor"
  >[]
  linkText?: string
  linkUrl?: string
  linkColor?: Color
} & Partial<Storyblok.CTATrackingEvent>

const MasterBulletPoints = ({
  itemType,
  backgroundColor,
  superscriptText,
  descriptionText,
  title,
  body,
  textAlignment = "left",
  headerSize = "small",
  bulletItems,
  iconInfoBlocks,
  listItems,
  linkText,
  linkUrl,
  linkColor,
  trackingEvent,
  trackingEventKey,
  trackingEventValue,
  ...props
}: MasterBulletPointsProps) => {
  const { animationRefCallback } = useScrollAnimateSelf(true)
  const typographyColor = backgroundColor
    ? getAccentColor(backgroundColor)
    : Color.Charcoal
  return (
    <div
      ref={animationRefCallback}
      className={classNames(
        backgroundColor ? getBackgroundColorClass(backgroundColor) : "",
        "rounded-lg col-span-4 flex flex-col gap-24 px-24 py-32 sm:col-span-2 md:col-span-3 md:gap-32 md:px-32 md:py-48 lg:col-span-6 lg:gap-40 lg:px-48 lg:py-64"
      )}
      {...props}
    >
      <div className="flex flex-col gap-8 md:gap-16">
        {superscriptText && (
          <Typography
            font="grotesk"
            weight="book"
            size="subscript-lg"
            color={typographyColor}
            text={superscriptText}
            className={classNames(
              textAlignment === "left" ? "text-left" : "text-center",
              "uppercase"
            )}
          />
        )}
        {headerSize === "small" ? (
          <Typography
            font="grotesk"
            weight="book"
            size="h5"
            text={title}
            color={typographyColor}
            element="h3"
            className={textAlignment === "left" ? "text-left" : "text-center"}
          />
        ) : (
          <Typography
            font="grotesk"
            weight="medium"
            size="h3"
            color={typographyColor}
            text={title}
            className={textAlignment === "left" ? "text-left" : "text-center"}
            element="h3"
          />
        )}
      </div>
      {(descriptionText || body) && (
        <>
          {descriptionText && (
            <Typography
              font="grotesk"
              weight="book"
              size="body-lg"
              color={typographyColor}
              text={descriptionText}
              className={textAlignment === "left" ? "text-left" : "text-center"}
            />
          )}
        </>
      )}

      {itemType === "icon_info" && iconInfoBlocks && (
        <div className="grid grid-cols-1 gap-32 md:grid-cols-2">
          {iconInfoBlocks.map(
            ({ iconUrl, iconAlt, titleText, bodyText }, index) => (
              <IconInfoBlock
                key={`${titleText}-${index}`}
                iconUrl={iconUrl}
                iconAlt={iconAlt}
                titleText={titleText}
                bodyText={bodyText}
                size={"small"}
                textColor={typographyColor}
              />
            )
          )}
        </div>
      )}

      {itemType === "bullet_items" && bulletItems && (
        <div className="flex flex-col gap-16">
          {bulletItems.map((bulletItem, index) => (
            <BulletItem
              key={index}
              text={bulletItem.text}
              answerText={bulletItem.answerText}
              size="body-md"
              textColor={typographyColor}
              iconOverrideColor={typographyColor}
            />
          ))}
        </div>
      )}

      {itemType === "list_items" && listItems && (
        <div className="flex flex-col gap-32">
          {listItems.map(({ titleText, bodyText }, index) => (
            <ListItem
              bodyText={bodyText}
              titleText={titleText}
              listNumber={index + 1}
              textColor={typographyColor}
              key={`${titleText}-${index}`}
            />
          ))}
        </div>
      )}

      {linkText && linkUrl && (
        <TextButton
          style="icon"
          text={linkText}
          icon={<ArrowIcon />}
          linkUrl={linkUrl}
          color={linkColor || typographyColor}
          trackingEvent={trackingEvent}
          trackingEventKey={trackingEventKey}
          trackingEventValue={trackingEventValue}
          className={classNames({
            "mx-auto sm:mx-0": itemType === "list_items",
          })}
        />
      )}
    </div>
  )
}

export default MasterBulletPoints
